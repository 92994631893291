
import Permissions from "~/graphql/Authentication/Permissions.gql";
import TenancyOverview from "~/graphql/Portfolio/TenancyOverview.gql";
import UpdateAssetManagementBusinessPlan from "~/graphql/Portfolio/Mutations/UpdateBusinessPlan.gql";
import CreateAssetManagementBusinessPlan from "~/graphql/Portfolio/Mutations/CreateBusinessPlan.gql";

import { PERMISSIONS } from "~/composables/usePermissions";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
import { InputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers.js";
import { getTotalActualsForFinancialRecords } from "~/helpers/portfolio/portfolio-financial-records-helpers.js";
import { getAskingPrice, getActualSalesPrice, getBusinessPlanForTenancy } from "~/helpers/portfolio/portfolio-tenancy-helpers";

import EditKeyValues from "~/pages/portfolio/components/Edit/EditKeyValues.vue";

import { filter } from "~/composables/useFilter";

export default {
  components: {
    EditKeyValues,
  },

  inheritAttrs: false,

  props: {
    tenancyId: {
      type: String,
      required: true,
    },
  },

  apollo: {
    tenancy: {
      query: TenancyOverview,
      variables() {
        return new InputBuilder()
          .setId(this.tenancyId)
          .setFilter({ matches: [{ category_type: [Operator.In, ["CAPEX", "capex"]] }] })
          .build();
      },
      update(data) {
        return data.assetManagementTenancy;
      },
    },

    me: {
      query: Permissions,
    },
  },

  data() {
    return {
      editBusinessPlanActive: false,
      editableBusinessPlans: [],
    };
  },

  created() {
    this.PERMISSIONS = PERMISSIONS;
  },

  computed: {
    filter() {
      return filter;
    },

    loading() {
      return this.$apollo.queries.tenancy.loading || this.$apollo.queries.me.loading;
    },

    capexes() {
      if (!this.tenancy == null || this.tenancy.assetManagementFinancialRecords.items == null) {
        return [];
      }

      return this.tenancy.assetManagementFinancialRecords.items;
    },

    capexTotals() {
      return getTotalActualsForFinancialRecords(this.capexes);
    },

    businessPlanPrice() {
      if (!this.tenancy == null) {
        return null;
      }

      return getBusinessPlanForTenancy(this.tenancy)?.businessPlanPrice ?? 0;
    },

    askingPrice() {
      return getAskingPrice(this.tenancy);
    },

    actualSalesPrice() {
      return getActualSalesPrice(this.tenancy);
    },

    allocatedPurchasePrice() {
      if (!this.tenancy == null) {
        return null;
      }

      return getBusinessPlanForTenancy(this.tenancy)?.allocatedPurchasePrice ?? 0;
    },

    allocatedPurchasePriceWithCost() {
      if (this.tenancy == null) {
        return null;
      }

      return getBusinessPlanForTenancy(this.tenancy)?.allocatedPurchasePriceWithCost ?? 0;
    },

    businessPlanPricePrM2() {
      if (this.tenancy == null || this.tenancy.area == null || this.businessPlanPrice == null) {
        return null;
      }

      return this.businessPlanPrice / this.tenancy.area;
    },

    askingPricePrM2() {
      if (this.tenancy == null || this.tenancy.area == null || this.askingPrice == null) {
        return null;
      }

      return this.askingPrice / this.tenancy.area;
    },

    actualSalesPricePrM2() {
      if (this.tenancy == null || this.tenancy.area == null || this.actualSalesPrice == null) {
        return null;
      }

      return this.actualSalesPrice / this.tenancy.area;
    },

    capexCurrency() {
      return this.capexes[0]?.currency;
    },

    areaUnitLocal() {
      return this.tenancy.areaUnit ? this.$t(`UNITS_${this.tenancy.areaUnit.toUpperCase()}`) : undefined;
    },
  },

  methods: {
    onEdit(key, value, label) {
      this.editableBusinessPlans = [
        {
          id: 0,
          key: key,
          value: parseInt(value, 10),
          type: "number",
          label: label,
        },
      ];

      this.editBusinessPlanActive = true;
    },

    async saveBusinessPlan(event) {
      const businessPlanId = getBusinessPlanForTenancy(this.tenancy)?.id;
      const input = {
        input: {
          ...event.reduce((acc, curr) => {
            acc[curr.key] = parseInt(curr.value, 10);
            return acc;
          }, {}),
        },
      };

      if (businessPlanId == null) {
        input.input.tenancyId = this.tenancyId;
        input.input.currency = "DKK";

        await this.$apollo.mutate({
          mutation: CreateAssetManagementBusinessPlan,
          variables: input,
        });

        this.$apollo.queries.tenancy.refetch();
      } else {
        input.input.id = businessPlanId;

        await this.$apollo.mutate({
          mutation: UpdateAssetManagementBusinessPlan,
          variables: input,
        });
      }

      this.editBusinessPlanActive = false;
    },

    hasPermission(permission) {
      if (this.meLoading) return false;

      return this.me.permissions.includes(permission);
    },

    filterCurrency(value) {
      return this.filter.currency(value, { currency: this.capexCurrency });
    },
  },
};
